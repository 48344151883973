<template>
  <div>
    <eden-page-header :title="'Combo Check'" :subtitle="'History'" />

    <eden-table-actions :title="'Combo Check History'" :show-search="false">
      <template slot="actions">
        <eden-period-filter @clear="setPageData" @confirm="filterCombos" />
        <el-button
          type="plain"
          icon="eden-icon-upload"
          class="ml-10"
          :disabled="loading || !pageData.length"
          @click="exporting = true"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>

    <p v-if="loading">Loading</p>
    <template v-else>
      <el-table :data="pageData">
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Date</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatDate(scope.row.day, "ddd do, m y") }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Number of Meals</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ scope.row.number_of_meals }}</span>
          </template>
        </el-table-column>

        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Stove off time</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.stove_time_off) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Last meal plated</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.last_meal_plated_at) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Last meal sealed</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.last_meal_sealed_at) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Last meal sleeved</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.last_meal_sleeved_at) }}</span
            >
          </template>
        </el-table-column>

        <el-table-column width="160">
          <template slot="header">
            <div class="table--header"></div>
          </template>
          <template slot-scope="scope">
            <a
              @click="
                $router.push({
                  name: 'combo-check.date',
                  params: { date: formatDate(scope.row.day, 'y-m-d') },
                })
              "
              class="text-underline text-primary text-cursor"
            >
              Expand details</a
            >
          </template>
        </el-table-column>
      </el-table>
    </template>
    <combo-check-history-export :show.sync="exporting" :data="pageData" />
  </div>
</template>
<script>
import * as actions from "@/store/action-types";
import combocheck from "@/requests/stewards/combocheck";
import ComboCheckHistoryExport from "@/components/Stewards/ComboCheck/Export/ComboCheckHistoryExport";

export default {
  name: "ComboHistory",
  components: { ComboCheckHistoryExport },
  data() {
    return {
      pageData: [],
      filterByDate: false,
      filteredDates: false,
      form: {
        start_date: null,
        end_date: null,
      },
      loading: false,
      page: 1,
      showPagination: true,
      exporting: false,
    };
  },
  computed: {
    combos() {
      return this.$store.getters.combo_history;
    },
    from() {
      return this.combos.pages[this.page].from;
    },
    to() {
      return this.combos.pages[this.page].to;
    },
    total() {
      return this.combos.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      const length = this.showPagination ? total || 0 : result;

      return `${length} Combo${length > 1 ? "s" : ""}`;
    },
  },

  watch: {
    page() {
      const pageFetched = !!this.combos.pages[this.page];
      if (!pageFetched) {
        this.getComboHistory();
      }
    },
  },
  created() {
    this.getComboHistory();
  },
  methods: {
    getComboHistory() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_COMBO_HISTORY, {
          page: this.page,
        })
        .then(() => {
          this.setPageData();
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    filterCombos(daterange) {
      this.form.start_date = daterange.from;
      this.form.end_date = daterange.to;
      this.loading = true;
      combocheck
        .filter(this.form.start_date, this.form.end_date)
        .then((response) => {
          if (response.data.status) {
            this.mixpanelTrack({
              event: "combo_check_view_history_filter",
            });
            this.pageData = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = JSON.parse(
        JSON.stringify(this.combos.pages[this.page].data),
      );
      this.form.start_date = null;
      this.form.end_date = null;
      this.showPagination = true;
      this.loading = false;
    },
  },
};
</script>
